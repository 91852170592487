import React from "react";

export default function Aside() {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                <img
                    src="dist/img/logo-comunitat-regants-panta-blau-30x30.png"
                    alt="AdminLTE Logo"
                    className="brand-image img-circle elevation-3"
                    style={{ backgroundColor: "white" }}
                />
                <span className="brand-text font-weight-light">Pantà de Riudecanyes</span>
            </a>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <a href="/comandes" className="nav-link">
                                <i className="fas fa-water nav-icon" />
                                <p>
                                    Comandes
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/certificats-drets" className="nav-link">
                                <i className="fas fa-award nav-icon" />
                                <p>
                                    Certificats de drets
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/contact" className="nav-link">
                                <i className="fas fa-envelope nav-icon" />
                                <p>
                                    Contacte
                                </p>
                            </a>
                        </li>
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
    );
}
