import axios from 'axios';

const createAxiosInstance = async () => {
    try {
        let instance;
        instance = axios.create({
            baseURL: process.env.REACT_APP_BACKEND
        });
        /*
        if (process.env.NODE_ENV === 'production') {
            const response = await fetch('https://api.ipify.org/?format=json');
            const data = await response.json();
            const ipAddress = data.ip;
            if (ipAddress === '195.135.62.17') {
                instance = axios.create({
                    baseURL: 'https://192.168.1.8:4430'
                });
            } else {
                instance = axios.create({
                    baseURL: process.env.REACT_APP_BACKEND
                });
        } else {
            instance = axios.create({
                baseURL: process.env.REACT_APP_BACKEND
            });
        }*/
        return instance;
    } catch (error) {
        let instance;
        instance = axios.create({
            baseURL: process.env.REACT_APP_BACKEND
        });
        return instance;
    }
};

const instancePromise = createAxiosInstance();

export default instancePromise;
