import React from "react";
import { useLocation } from "react-router-dom"
import Comanda from "./Comanda";

export default function DetallsComanda() {
    const comanda = useLocation().state.comanda

    document.title = "Comanda en detall - Pantà de Riudecanyes"

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Comanda en detall</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item"><a href="/">Comandes</a></li>
                                <li className="breadcrumb-item active">Detall</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <Comanda accio='detalls' comanda={comanda} />
        </div >
    );
}