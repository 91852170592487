import React from 'react';
import { useLocation } from 'react-router-dom';

export default function LinkInvalid() {
    const location = useLocation();
    const currentPath = location.pathname;
    document.title = "Enllaç invàlid - Pantà de Riudecanyes"
    return (
        <main className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                    </div>
                    <div className="card-body">
                        <h3 className="login-box-msg"><i className="fas fa-exclamation-circle"></i> Pàgina no trobada</h3>

                        <div>La pàgina sol·licitada "{currentPath}" no s'ha pogut trobar.</div>
                    </div>
                </div>
            </div>
        </main>
    );
}