import React from 'react'

export default function Home() {
    document.title = "Pantà de Riudecanyes"

    return (
        <div className="content-wrapper">
            <div className="login-page">
                <div className="row">
                    <div className="small-box bg-primary col-10 offset-1 offset-md-0 col-md-5 p-4">
                        <a href="/comandes">
                            <div className="inner">
                                <h3 className="mb-4">Comandes</h3>
                                <p>Pot crear, consultar, editar i elimnar comandes d'aigua</p>
                            </div>
                            <div className="icon">
                                <i className="fas fa-water"></i>
                            </div>
                        </a>
                    </div>
                    <div className="small-box bg-success col-10 offset-1 col-md-5 offset-md-2 p-4">
                        <a href="/certificats-drets">
                            <div className="inner">
                                <h3 className="mb-4">Certificats</h3>
                                <p>Pot crear i consultar certificats de drets</p>
                            </div>
                            <div className="icon">
                                <i className="fas fa-award"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
