import React, { useState } from "react";
import Comanda from "./Comanda";

export default function NovaComanda() {

    document.title = "Nova comanda - Pantà de Riudecanyes"

    var [comanda, setComanda] = useState({
        comuner: {
            codi_comuner: null
        },
        tipusAigua: {
            tipus_aigua: null
        },
        aCompteOExtra: 0,
        presa: null,
        agrupacio: null,
        hores: null,
        dies: 1
    })


    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Nova comanda</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item"><a href="/comandes">Comandes</a></li>
                                <li className="breadcrumb-item active">Nova Comanda</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <Comanda accio='crear' comanda={comanda} setComanda={setComanda} />
        </div>
    );
}