import React from 'react';
import instancePromise from '../axiosInstance';
import { alerta, invalidInputMessage, handleInvalidInput } from '../Global'

export default function ValidarCertificatDret() {
    document.title = "Validar certificat de dret - Pantà de Riudecanyes"

    function handleSubmit(event) {
        event.preventDefault()

        var btnValidar = document.getElementById("btnValidar")
        btnValidar.disabled = true

        instancePromise.then(instance => {
            instance
                .get(`/certificat-dret/` + document.getElementById("codi").value, {
                    responseType: 'arraybuffer',
                })
                .then(response => {
                    btnValidar.disabled = false;

                    const byteArray = new Uint8Array(response.data);
                    const file = new Blob([byteArray], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = document.getElementById("codi").value + '.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    btnValidar.disabled = false;
                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió")
                    } else {
                        alerta(new TextDecoder().decode(error.response.data));
                    }
                });
        });
    }

    return (
        <main className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Validar certificat de dret</p>

                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-4">
                                <input type="text" id="codi" className="form-control" placeholder="Codi" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-award"></span>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" id="btnValidar" className="btn btn-primary btn-block">Validar</button>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}