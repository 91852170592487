import React from "react";
import { useNavigate, useLocation } from "react-router-dom"

export default function DetallsComanda() {
    const navigate = useNavigate();
    const comanda = useLocation().state.comanda
    const horaris = useLocation().state.comanda.horaris

    document.title = "Horari - Pantà de Riudecanyes"

    console.log(horaris)

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Horari</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item"><a href="/">Comandes</a></li>
                                <li className="breadcrumb-item active">Horari</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="card card-primary card-outline">
                    <div className="card-body">
                        {horaris.length !== 0 ? (
                            <>
                                <div className="mb-4">
                                    <div><b>Presa</b></div>
                                    <div>{horaris[0].presa.nom}</div>
                                </div>
                                <div className="mb-4">
                                    {comanda.aCompteOExtra === 2 ? (
                                        <>
                                            <div><b>Aigua a compte</b></div>
                                            <div>{comanda.tipusAigua.descripcio}</div>
                                        </>
                                    ) : (
                                        <>
                                            {comanda.aCompteOExtra === 3 ? (
                                                <>
                                                    <div><b>Aigua extra</b></div>
                                                    <div>{comanda.tipusAigua.descripcio}</div>
                                                </>
                                            ) : (
                                                <>
                                                    {comanda.tipusAigua.tipus_aigua === 1 ? (
                                                        <>
                                                            <div><b>Reg d'estiu</b></div>
                                                            <div>Començarà a regar el {new Date(horaris[0].dataInici).getDate()}/{new Date(horaris[0].dataInici).getMonth() + 1}/{new Date(horaris[0].dataInici).getFullYear()}</div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div><b>Aigua de setembre</b></div>
                                                            <div>{comanda.tipusAigua.descripcio}</div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                {comanda.tipusAigua.tipus_aigua === 1 ? (
                                    <>
                                        <div className="mb-4">
                                            <div><b>Horari</b></div>
                                            <div>
                                                {horaris.map((horari) => (
                                                    <div>
                                                        {new Date(horari.dataInici).toLocaleDateString('ca-ES', { weekday: 'long' }).toUpperCase()} de {new Date(horari.dataInici).getHours()}:{new Date(horari.dataInici).getMinutes().toString().padStart(2, '0')}h a {new Date(horari.dataInici).getHours() + horari.duracio}:{new Date(horari.dataInici).getMinutes().toString().padStart(2, '0')}h
                                                        <br></br>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ) : (<>
                                    < div className="mb-4">
                                        <div><b>Horari</b></div>
                                        <div>
                                            {horaris.map((horari) => (
                                                <div>
                                                    {new Date(horari.dataInici).toLocaleDateString('ca-ES', { weekday: 'long' }).toUpperCase()} {new Date(horari.dataInici).getDate()}/{new Date(horari.dataInici).getMonth() + 1}/{new Date(horari.dataInici).getFullYear()} de {new Date(horari.dataInici).getHours()}:{new Date(horari.dataInici).getMinutes().toString().padStart(2, '0')}h a {new Date(horari.dataInici).getHours() + horari.duracio}:{new Date(horari.dataInici).getMinutes().toString().padStart(2, '0')}h
                                                    <br></br>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                                )}
                                <div className="mb-4">
                                    <div><b>Cabal</b></div>
                                    <div>
                                        {horaris[0].quantitatAigua >= 24 && horaris[0].quantitatAigua % 24 === 0 ? (
                                            <div>
                                                {horaris[0].quantitatAigua / 24} {horaris[0].quantitatAigua === 24 ? " regadora" : " regadores"}
                                            </div>
                                        ) : (
                                            <div>
                                                {(horaris[0].quantitatAigua * 0.27777777777778).toFixed(2)} l/s
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (<></>)}
                        <div className="mt-4 text-right" style={{ width: 300 }}>
                            <button className="btn btn-danger" onClick={() => navigate(-1)}>Enrere</button>
                        </div>
                    </div >
                </div>
            </section >
        </div >
    );
}