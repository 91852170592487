import React, { useState } from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import instancePromise from '../axiosInstance';
import { alerta, handleInvalidContrasenya, invalidInputMessage } from '../Global'

export default function RecoverPassword() {
    const navigate = useNavigate();
    const token = new URLSearchParams(window.location.search).get("token")

    document.title = "Recuperar Contrasenya - Pantà de Riudecanyes"

    var [tokenValid, setTokenValid] = useState(null)
    var [passwordChanged, setPasswordChanged] = useState(false)

    useEffect(checkToken, [token])

    function checkToken() {
        instancePromise.then(instance => {
            instance
                .get(`/usuari/check_token/`,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                )
                .then(() => {
                    setTokenValid(true)
                })
                .catch(error => {
                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió. Intenta-ho més tard")
                    } else {
                        setTokenValid(false)
                    }
                });
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (document.getElementById("password").value !== document.getElementById("confirm_password").value) {
            alerta("Les contrasenyes no coincideixen")
            return
        }
        var botoEnviar = document.getElementById('botoEnviar')
        botoEnviar.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/usuari/reset_password/`,
                    {
                        password: document.getElementById("password").value,
                        confirmPassword: document.getElementById("confirm_password").value
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                )
                .then(() => {
                    botoEnviar.disabled = false
                    setPasswordChanged(true)
                })
                .catch(error => {
                    botoEnviar.disabled = false
                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió. Intenta-ho més tard")
                    } else {
                        alerta(error.response.data);
                    }
                });
        });
    }

    return (
        <main className="hold-transition login-page">
            {tokenValid === null ? (
                <>
                    <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                    <div class="text-bold pt-2">Carregant...</div>
                </>
            ) : (
                <div className="login-box">
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                        </div>
                        <div className="card-body">
                            {tokenValid ? (<React.Fragment>
                                {passwordChanged ? (
                                    <React.Fragment>
                                        <h4>Contrasenya actualitzada correctament</h4>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => navigate("/login")}>Iniciar sessió</button>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <p className="login-box-msg">Introdueixi la nova contrasenya</p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input type="password" id="password" pattern=".{8,}" className="form-control" placeholder="Contrasenya" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidContrasenya(e) }} onInvalid={handleInvalidContrasenya} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="password" id="confirm_password" pattern=".{8,}" className="form-control" placeholder="Confirmar contrasenya" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidContrasenya(e) }} onInvalid={handleInvalidContrasenya} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <button id="botoEnviar" type="submit" className="btn btn-primary btn-block">Canviar contrasenya</button>
                                        </form>
                                    </React.Fragment>)}
                            </React.Fragment>
                            ) : (<React.Fragment>
                                <h4>Enllaç invàlid</h4>
                                <div>Cal tornar a sol·licitar una nova contrasenya</div>
                            </React.Fragment>)}
                        </div>
                    </div>
                </div>)}
        </main>
    );
}