import React from 'react';
import instancePromise from '../axiosInstance';
import { alerta, handleInvalidEmail, invalidInputMessage, success } from '../Global'
import { useState } from 'react';

export default function ForgotPassword() {
    document.title = "Recuperar Compte - Pantà de Riudecanyes"

    var [email, setEmail] = useState("")

    function sendRecoverPasswordEmail(event) {
        event.preventDefault()

        var botoEnviar = document.getElementById('botoEnviar')
        botoEnviar.disabled = true

        instancePromise.then(instance => {
            instance
                .post('/usuari/send_recover_password_email/', {
                    email: document.getElementById('email').value
                })
                .then(() => {
                    botoEnviar.disabled = false
                    setEmail(document.getElementById('email').value)
                    success("Correu enviat")
                })
                .catch(error => {
                    botoEnviar.disabled = false
                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió. Intenta-ho més tard")
                    } else {
                        alerta(error.response.data);
                    }
                });
        });
    }

    function resend() {
        var btnResend = document.getElementById("btnResend")
        btnResend.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/usuari/send_recover_password_email`, {
                    email: email
                })
                .then(() => {
                    btnResend.disabled = false
                    success("Correu reenviat")
                })
                .catch(error => {
                    btnResend.disabled = false
                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió. Intenta-ho més tard")
                    } else {
                        alerta(error.response.data);
                    }
                });
        });
    }

    return (
        <main className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                    </div>
                    <div className="card-body">
                        {email !== "" ? (
                            <React.Fragment>
                                <h4>Restablir contrasenya</h4>
                                <div className="mb-2">Per a canviar la contrasenya li hem enviat un correu amb els passos a seguir.</div>
                                <div className="mb-3">Revisi la safata d'entrada, si no el troba, comprovi el correu brossa.</div>
                                <div>
                                    No ha rebut el correu? <button type="button" id="btnResend" style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        color: 'blue',
                                        textDecoration: 'underline',
                                    }} onClick={resend}>Reenviar</button>
                                </div>
                            </React.Fragment>
                        ) : (<React.Fragment>
                            <p className="login-box-msg">T'enviarem un enllaç per canviar la contrasenya</p>
                            <form onSubmit={sendRecoverPasswordEmail}>
                                <div className="input-group mb-3">
                                    <input type="email" id="email" className="form-control" placeholder="Email" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidEmail(e) }} onInvalid={handleInvalidEmail} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <button id="botoEnviar" type="submit" className="btn btn-primary btn-block">Enviar</button>
                                <p className="mt-3 mb-0">
                                    <a href="/login">Cancel·lar</a>
                                </p>
                            </form>
                        </React.Fragment>)}
                    </div>
                </div>
            </div>
        </main>
    );
}