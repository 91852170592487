import React from 'react';

export default function PrivacyPolicy() {
    document.title = "Politica de privacitat - Pantà de Riudecanyes"
    return (
        <div>
            <section className="content">
                <div className="card">
                    <div className="card-header">
                        <h1 className="entry-title" itemprop="headline">Política de Privadesa del Pantà de Riudecanyes</h1>
                        <p>Actualitzada el 4 d'octubre de 2023</p>
                        <p>Aquesta Política de Privadesa descriu com el <b>Pantà de Riudecanyes</b> recopila, utilitza i comparteix informació personal quan interactues amb nosaltres a través de la nostra pàgina web sota el domini <strong><i>pantaderiudecanyes.cat</i></strong> i les nostres aplicacions mòbils <strong><i>CRP Riudecanyes</i></strong> en <a href="https://play.google.com/store/apps/details?id=cat.panta.appregants">Google Play</a> i <a href="https://apps.apple.com/us/app/crp-riudecanyes/id1513541637">App Store</a>.</p>
                    </div>
                    <div className="card-body">
                        <h3>Informació que recopilem</h3>
                        <ul>
                            <li>
                                <p>La informació que ens proporciones quan et registres en la nostra pàgina web, com ara el teu nom, adreça de correu electrònic i altra informació de contacte.</p>
                            </li>
                            <li>
                                <p>Tant a la nostra pàgina web com a les nostres aplicacions, podem recopilar automàticament informació sobre la manera com interactues amb els nostres serveis, com les pàgines visitades, les funcions utilitzades i el temps d'ús.</p>
                            </li>
                        </ul>
                        <h3>Com utilitzem la teva informació</h3>
                        <p>La informació recopilada s'utilitza per a:</p>
                        <ul>
                            <li>
                                <p>Proporcionar i mantenir els nostres serveis digitals.</p>
                            </li>
                            <li>
                                <p>Personalitzar i millorar lexperiència de lusuari.</p>
                            </li>
                            <li>
                                <p>Enviar comunicacions relacionades amb els nostres serveis.</p>
                            </li>
                        </ul>
                        <h3>Compartir informació</h3>
                        <p>No compartim informació personal amb tercers a no ser que sigui necessari per al funcionament dels nostres serveis o estiguem legalment obligats a fer-ho.</p>

                        <h3>Canvis en aquesta Política de Privadesa</h3>
                        <p>Ens reservem el dret d'actualitzar la nostra Política de Privadesa en qualsevol moment. Et notificarem sobre qualsevol canvi publicant la nova Política de Privadesa a la nostra pàgina web.</p>

                        <h3>Condicions d'ús</h3>
                        <p>Aquesta Política de Privadesa es complementa amb les nostres Condicions d'Ús, les quals són aplicables tant a la nostra pàgina web com a les nostres aplicacions mòbils. Podeu revisar les <a href="https://oficinavirtual.pantaderiudecanyes.cat/avis-legal">Condicions d'Ús</a>.</p>

                        <h3>Contacte</h3>
                        <p>Si tens preguntes sobre aquesta Política de Privadesa, si us plau contacta'ns a <a href="mailto:c%6frreu@pa%6etader%69udecan%79e%73.%63at">correu@pantaderiudecanyes.cat</a>.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}