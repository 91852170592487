import React from 'react';

export default function Maintenance() {
    return (
        <main className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <h1><b>Pantà de Riudecanyes</b></h1>
                    </div>
                    <div className="card-body">
                        <h5 className="login-box-msg"><i className="fas fa-hammer"></i> Estem en manteniment</h5>
                        <p>En aquests moments estem millorant la pàgina web. Torna a intentar-ho més tard.</p>
                    </div>
                </div>
            </div>
        </main>
    );
}