import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import instancePromise from '../axiosInstance';
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { alerta, handleInvalidContrasenya, handleInvalidEmail, invalidInputMessage } from '../Global'
import "./LineaConTexto.css"

export default function Login() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['auth_token']);

    document.title = "Iniciar Sessió - Pantà de Riudecanyes"

    useEffect(checkToken, [cookies, navigate])
    function checkToken() {
        if (cookies.auth_token != null) {
            navigate("/", { replace: true })
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        var email = document.getElementById("email").value
        var password = document.getElementById("password").value

        var btnLogin = document.getElementById("btnLogin")
        btnLogin.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/usuari/login/`, {
                    email: email,
                    password: password
                })
                .then(response => {
                    btnLogin.disabled = false

                    var decoded = jwt_decode(response.data);
                    var age = decoded.exp - decoded.iat;
                    setCookie('auth_token', response.data, { path: '/', maxAge: age });
                    if (document.getElementById("remember").checked) {
                        localStorage.setItem('remembered_email', email)
                        localStorage.setItem('remembered_password', password)
                    } else {
                        localStorage.removeItem('remembered_email', email)
                        localStorage.removeItem('remembered_password', password)
                    }

                    if (window.location.pathname === "/login") {
                        navigate("/")
                    }
                    window.location.reload();
                })
                .catch(error => {
                    btnLogin.disabled = false

                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió. Intenta-ho més tard")
                    } else {
                        if (error.response.status === 403) {
                            alerta("Contranseya incorrecta")
                        } else {
                            alerta(error.response.data);
                        }
                    }
                });
        });
    }

    return (
        <main className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Inicia sessió per continuar</p>

                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                                <input type="email" id="email" className="form-control" placeholder="Email" defaultValue={localStorage.getItem('remembered_email') ? localStorage.getItem('remembered_email') : ""} required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidEmail(e) }} onInvalid={handleInvalidEmail} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" id="password" pattern=".{8,}" className="form-control" placeholder="Contrasenya" defaultValue={localStorage.getItem('remembered_password') ? localStorage.getItem('remembered_password') : ""} required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidContrasenya(e) }} onInvalid={handleInvalidContrasenya} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="remember" />
                                        <label className="custom-control-label" htmlFor="remember">Recorda'm</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="submit" id="btnLogin" className="btn btn-primary btn-block">Entrar</button>
                                </div>
                            </div>
                        </form>

                        <p className="mb-1">
                            <a href="/account/forgot-password">He oblidat la contrasenya</a>
                        </p>
                        <p className="mb-3">
                            No tens compte? <a href="/register" className="text-center">Registra't</a>
                        </p>
                        <div class="social-auth-links text-center mb-0">
                            <div class="contenedor mb-2">
                                <div class="linea"></div>
                                <div class="texto">O continua amb</div>
                                <div class="linea"></div>
                            </div>
                            <a class="btn btn-block btn-default" href={process.env.REACT_APP_VALID_URL + `/o/oauth2/auth?scope=autenticacio_usuari&redirect_uri=` + process.env.REACT_APP_REDIRECT_URI_AOC + `&response_type=code&client_id=tramits.pantaderiudecanyes.cat&access_type=online&approval_prompt=auto`}>
                                <img src="dist/img/id-cat.png" width="60" alt="idCat" />
                                <div>idCAT mòbil, certificat digital o cl@ve</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}