import React, { useState } from 'react';
import instancePromise from '../axiosInstance';
import { useNavigate } from "react-router-dom"
import { alerta, handleInvalidCheckbox, handleInvalidContrasenya, handleInvalidEmail, handleInvalidInput, invalidInputMessage } from '../Global'

export default function Register() {
    const navigate = useNavigate();

    document.title = "Registrar-se - Pantà de Riudecanyes"

    var [registrat, setRegistrat] = useState(false)

    function handleSubmit(event) {
        event.preventDefault();
        if (document.getElementById("password").value !== document.getElementById("retype_password").value) {
            alerta("Les contrasenyes no coincideixen")
            return
        }

        var btnRegister = document.getElementById("btnRegister")
        btnRegister.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/usuari/register`, {
                    Perfil: {
                        email: document.getElementById("email").value,
                        username: document.getElementById("username").value,
                        password: document.getElementById("password").value,
                        confirmPassword: document.getElementById("retype_password").value
                    },
                    Persona: {
                        nif: document.getElementById("dni").value,
                        data_naix: document.getElementById("naixement").value
                    }
                })
                .then(() => {
                    btnRegister.disabled = false
                    setRegistrat(true)
                })
                .catch(error => {
                    btnRegister.disabled = false
                    if (error.code === "ERR_NETWORK") {
                        alerta("Error de connexió. Intenta-ho més tard")
                    } else {
                        alerta(error.response.data);
                    }
                });
        });
    }

    return (
        <main className="hold-transition register-page">
            <div className="register-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                    </div>
                    <div className="card-body">
                        {registrat ? (
                            <React.Fragment>
                                <h4>Benvingut/da</h4>
                                <div className="mb-3">El teu compte està llest i ja pots començar a utilitzar-lo.</div>
                                <button className="btn btn-primary float-right" onClick={() => navigate("/login")}>Iniciar sessió</button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <form onSubmit={handleSubmit}>

                                    <h5>Dades personals</h5>

                                    <div className="input-group mb-3">
                                        <input type="text" id="username" className="form-control" placeholder="Nom i Cognoms" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="email" id="email" className="form-control" placeholder="Email" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidEmail(e) }} onInvalid={handleInvalidEmail} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" id="password" pattern=".{8,}" className="form-control" placeholder="Contrasenya" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidContrasenya(e) }} onInvalid={handleInvalidContrasenya} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" id="retype_password" pattern=".{8,}" className="form-control" placeholder="Confirmar contrasenya" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidContrasenya(e) }} onInvalid={handleInvalidContrasenya} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <h5>Dades del comuner a gestionar</h5>

                                    <div className="input-group mb-3">
                                        <input type="text" id="dni" className="form-control" placeholder="DNI" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-id-card"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>Data de naixement</div>

                                    <div className="input-group mb-3">
                                        <input type="date" id="naixement" className="form-control" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-7">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" name="terms" className="custom-control-input" id="agreeTerms" required onClick={handleInvalidCheckbox} onInvalid={handleInvalidCheckbox} />
                                                <label className="custom-control-label" htmlFor="agreeTerms">Accepto els <a href="/avis-legal" target="_blank" rel="noopener noreferrer">termes</a></label>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <button type="submit" id="btnRegister" className="btn btn-primary btn-block">Registrar-se</button>
                                        </div>
                                    </div>
                                </form>
                                <a href="/login" className="text-center">Iniciar sessió</a>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}