import React, { useEffect, useState } from "react";
import instancePromise from '../axiosInstance';
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"
import { handleError, alerta, invalidInputMessage, handleInvalidNumber, invalidSelectMessage, handleInvalidSelect } from '../Global'

export default function Comanda({ accio, comanda, setComanda }) {
    const navigate = useNavigate();
    const [cookies] = useCookies(['auth_token']);

    console.log(comanda)

    var [personesDisponibles, setPersonesDisponibles] = useState([])
    var [tipusAiguaDisponibles, setTipusAiguaDisponibles] = useState([])
    var [permetExtra, setPermetExtra] = useState(false)
    var [presesDisponibles, setPresesDisponibles] = useState([])
    var [agrupacionsDisponibles, setAgrupacionsDisponibles] = useState([])
    var [preu, setPreu] = useState(0)

    useEffect(() => {
        if (accio === 'crear') {
            loadPersones();
            loadTipusAigua();
        }
        if (accio === 'editar') {
            setForm();
            if (comanda.tipusAigua.tipus_aigua !== 7) {
                loadAgrupacions(comanda.tipusAigua.tipus_aigua === 1 ? `` : `/no-estiu`);
            }
        }
        // eslint-disable-next-line
    }, [cookies, navigate]);


    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);
    const alertUser = (e) => {
        e.preventDefault();
        if (document.getElementById("botoGuardarComanda"))
            e.returnValue = "";
    };


    function loadPersones() {
        instancePromise.then(instance => {
            instance
                .get(`/usuari/persona`, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setPersonesDisponibles(response.data)
                    if (response.data.length === 0) {
                        Swal.fire({
                            title: "Per crear una nova comanda d'aigua cal afegir un comuner",
                            icon: 'info',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Afegir comuner'
                        })
                            .then(() => {
                                navigate("/afegir-persona")
                            })
                    }
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }


    function loadTipusAigua() {
        instancePromise.then(instance => {
            instance
                .get(`/tipus-aigua/`, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setTipusAiguaDisponibles(response.data)
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }


    function setForm() {
        if (comanda.tipusAigua.tipus_aigua === 7) {
            document.getElementById("nHores").value = comanda.hores
            return
        }

        document.getElementById("nHores").value = comanda.hores

        if (comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null) {
            document.getElementById("horariSelect").value = comanda.mtn !== null ? comanda.mtn : 0

            document.getElementById("nDies").value = comanda.dies !== 0 ? comanda.dies : null

            setCheckGroups(comanda.opcio1, 1)
            setCheckGroups(comanda.opcio2, 2)
            setCheckGroups(comanda.opcio3, 3)

            document.getElementById("regarAmbComuner").value = comanda.comunerJunt
        }

        if (comanda.agrupacio === null && (comanda.tipusAigua.tipus_aigua === 7 || comanda.tipusAigua.tipus_aigua > 1000)) {
            document.getElementById("observacions").value = comanda.observacions
        }
    }


    function setCheckGroups(opcio, num) {
        if ((opcio & 1) !== 0) document.getElementById("dilluns" + num).click();
        if ((opcio & 2) !== 0) document.getElementById("dimarts" + num).click();
        if ((opcio & 4) !== 0) document.getElementById("dimecres" + num).click();
        if ((opcio & 8) !== 0) document.getElementById("dijous" + num).click();
        if ((opcio & 16) !== 0) document.getElementById("divendres" + num).click();
        if ((opcio & 32) !== 0) document.getElementById("dissabte" + num).click();
        if ((opcio & 64) !== 0) document.getElementById("diumenge" + num).click();
    }


    function changePersona(event) {
        event.target.setCustomValidity("");
        setComanda({
            ...comanda,
            comuner: {
                ...comanda.comuner,
                codi_comuner: parseInt(event.target.value)
            },
            tipusAigua: {
                ...comanda.tipusAigua,
                tipus_aigua: null
            }
        })
        let persona = event.target.value
        if (persona !== "") {
            setTimeout(() => document.getElementById("aiguaSelect").selectedIndex = 0, 1)
        }
    }


    function changeTipusAigua(event) {
        event.target.setCustomValidity("");
        setComanda({
            ...comanda,
            tipusAigua: {
                ...comanda.tipusAigua,
                tipus_aigua: parseInt(event.target.value)
            },
            presa: null,
            aCompteOExtra: 0,
            hores: null
        })
        let tipusAigua = event.target.value
        if (tipusAigua === "7") {
            setTimeout(() => document.getElementById("nHores").value = null, 1)
        } else if (tipusAigua > 1000) {
            setTimeout(() => document.getElementById("aCompteOExtraSelect").selectedIndex = 0, 1)
        } else if (tipusAigua !== "") {
            setTimeout(() => document.getElementById("presaSelect").selectedIndex = 0, 1)
        }
        if (tipusAigua > 1000) {
            instancePromise.then(instance => {
                instance
                    .get(`/tipus-aigua/extra`, {
                        headers: {
                            'Authorization': 'Bearer ' + cookies.auth_token
                        }
                    })
                    .then(response => {
                        setPermetExtra(response.data)
                    })
                    .catch(error => {
                        handleError(error, navigate)
                    });
            });
        }

        instancePromise.then(instance => {
            instance
                .get(`/comuner/` + document.getElementById("personaSelect").value + `/preses`, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setPresesDisponibles(response.data)
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }


    function changeACompteOExtra(event) {
        event.target.setCustomValidity("");
        setComanda({
            ...comanda,
            presa: null,
            aCompteOExtra: parseInt(event.target.value),
        })
        setTimeout(() => document.getElementById("presaSelect").selectedIndex = 0)
    }


    function changePresa(event) {
        event.target.setCustomValidity("");
        setComanda({
            ...comanda,
            presa: {
                ...comanda.presa,
                codi_presa: parseInt(event.target.value)
            },
            agrupacio: null,
            hores: null,
            dies: 1
        })
        comanda.presa = { codi_presa: event.target.value }
        setTimeout(() => document.getElementById("nHores").value = null, 1)
        if (comanda.tipusAigua.tipus_aigua === 1) {
            loadAgrupacions(``)
        } else {
            loadAgrupacions(`/no-estiu`)
        }
    }

    function loadAgrupacions(estiu) {
        instancePromise.then(instance => {
            instance
                .get(`/presa/` + comanda.presa.codi_presa + `/agrupacions` + estiu, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setAgrupacionsDisponibles(response.data)
                    if (response.data.length !== 0) {
                        if (accio === 'crear') {
                            setTimeout(() => document.getElementById("agrupacioSelect").selectedIndex = 0, 1)
                        } else {
                            if (comanda.agrupacio !== null) {
                                setTimeout(() => document.getElementById("agrupacioSelect").value = comanda.agrupacio.codi_agrupacio, 1)
                            }
                        }
                    }
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }


    function changeAgrupacio(event) {
        event.target.setCustomValidity("");
        if (event.target.value !== "") {
            setComanda({
                ...comanda,
                agrupacio: {
                    ...comanda.agrupacio,
                    codi_agrupacio: parseInt(event.target.value)
                },
                hores: null,
                dies: 1
            })
        } else {
            setComanda({
                ...comanda,
                agrupacio: null,
                hores: null,
                dies: 1
            })
        }
        setTimeout(() => document.getElementById("nHores").value = null, 1)
    }


    function changeHores(event) {
        setComanda({
            ...comanda,
            hores: event.target.value !== "" ? parseInt(event.target.value) : "",
        })
    }

    function changeDies(event) {
        setComanda({
            ...comanda,
            dies: event.target.value !== "" ? parseInt(event.target.value) : null,
        })
        resetCheckGroups()
    }


    function resetCheckGroups() {
        for (var i = 1; i <= 3; i++) {
            let chk_group = document.getElementsByName("chk_group" + i)
            for (let j = 0; j < chk_group.length; j++) {
                chk_group[j].checked = false
                chk_group[j].disabled = false
            }
        }
    }

    function checkNDays(element, num) {
        element.checked = !element.checked
        var chk_group = document.getElementsByName("chk_group" + num)
        let dies = 0
        for (let i = 0; i < chk_group.length; i++) {
            if (chk_group[i].checked) {
                dies++
            }
        }
        if (dies === parseInt(document.getElementById("nDies").value)) {
            for (let i = 0; i < chk_group.length; i++) {
                if (!chk_group[i].checked) {
                    chk_group[i].disabled = true
                }
            }
        } else {
            for (let i = 0; i < chk_group.length; i++) {
                chk_group[i].disabled = false
            }
        }
    }

    function calcularOpcions(chk_group_name) {
        var opcio = 0
        var chk_group = document.getElementsByName(chk_group_name)
        for (let i = 0; i < chk_group.length; i++) {
            if (chk_group[i].checked) {
                opcio += Math.pow(2, i)
            }
        }
        return opcio
    }

    function createCheckGroups(num) {
        return (<div className="mb-2">
            <div style={{ fontSize: '0.85em' }}><b>Opció {num}</b></div>
            <div className="row ml-1">
                <div className="custom-control custom-checkbox col-xl-1 col-md-2 col-4"><input className="custom-control-input" type="checkbox" name={"chk_group" + num} id={"dilluns" + num} value="Dilluns" onChange={(e) => checkNDays(e, num)} /><label className="custom-control-label" htmlFor={"dilluns" + num}>Dilluns</label></div>
                <div className="custom-control custom-checkbox col-xl-1 col-md-2 col-4"><input className="custom-control-input" type="checkbox" name={"chk_group" + num} id={"dimarts" + num} value="Dimarts" onChange={(e) => checkNDays(e, num)} /><label className="custom-control-label" htmlFor={"dimarts" + num}>Dimarts</label></div>
                <div className="custom-control custom-checkbox col-xl-1 col-md-2 col-4"><input className="custom-control-input" type="checkbox" name={"chk_group" + num} id={"dimecres" + num} value="Dimecres" onChange={(e) => checkNDays(e, num)} /><label className="custom-control-label" htmlFor={"dimecres" + num}>Dimecres</label></div>
                <div className="custom-control custom-checkbox col-xl-1 col-md-2 col-4"><input className="custom-control-input" type="checkbox" name={"chk_group" + num} id={"dijous" + num} value="Dijous" onChange={(e) => checkNDays(e, num)} /><label className="custom-control-label" htmlFor={"dijous" + num}>Dijous</label></div>
                <div className="custom-control custom-checkbox col-xl-1 col-md-2 col-4"><input className="custom-control-input" type="checkbox" name={"chk_group" + num} id={"divendres" + num} value="Divendres" onChange={(e) => checkNDays(e, num)} /><label className="custom-control-label" htmlFor={"divendres" + num}>Divendres</label></div>
                <div className="custom-control custom-checkbox col-xl-1 col-md-2 col-4"><input className="custom-control-input" type="checkbox" name={"chk_group" + num} id={"dissabte" + num} value="Dissabte" onChange={(e) => checkNDays(e, num)} /><label className="custom-control-label" htmlFor={"dissabte" + num}>Dissabte</label></div>
                <div className="custom-control custom-checkbox col-xl-1 col-md-2 col-4"><input className="custom-control-input" type="checkbox" name={"chk_group" + num} id={"diumenge" + num} value="Diumenge" onChange={(e) => checkNDays(e, num)} /><label className="custom-control-label" htmlFor={"diumenge" + num}>Diumenge</label></div>
            </div>
        </div>)
    }


    function translateHorari(mtn) {
        if (mtn === 0 || mtn === null) return "Indiferent";
        if (mtn === 1) return "De nit";
        if (mtn === 2) return "De dia, preferiblement de matí";
        if (mtn === 4) return "De dia, preferiblement de tarda";
        if (mtn === 8) return "De dia";
        return "";
    }

    function translatePreferencia(opcio) {
        let aux = "";
        if ((opcio & 1) !== 0) aux += "Dilluns ";
        if ((opcio & 2) !== 0) aux += "Dimarts ";
        if ((opcio & 4) !== 0) aux += "Dimecres ";
        if ((opcio & 8) !== 0) aux += "Dijous ";
        if ((opcio & 16) !== 0) aux += "Divendres ";
        if ((opcio & 32) !== 0) aux += "Dissabte ";
        if ((opcio & 64) !== 0) aux += "Diumenge ";
        return aux;
    }

    function translatePreferencies(opcio1, opcio2, opcio3) {
        let aux = "";
        if (opcio1 !== 0) {
            aux += translatePreferencia(opcio1)
            if (opcio2 !== 0) {
                aux += " o " + translatePreferencia(opcio2)
            }
            if (opcio3 !== 0) {
                aux += " o " + translatePreferencia(opcio3)
            }
        } else {
            if (opcio2 !== 0) {
                aux += translatePreferencia(opcio2)
                if (opcio3 !== 0) {
                    aux += " o " + translatePreferencia(opcio3)
                }
            } else {
                if (opcio3 !== 0) {
                    aux += translatePreferencia(opcio3)
                } else {
                    aux = ""
                }
            }
        }
        return aux;
    }


    function handleSubmit(event) {
        event.preventDefault()
        if (comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null) {
            var opcio1 = calcularOpcions("chk_group1")
            var opcio2 = calcularOpcions("chk_group2")
            var opcio3 = calcularOpcions("chk_group3")
            if ((opcio1 !== 0 && opcio2 === 0 && opcio3 === 0) || (opcio1 === 0 && opcio2 !== 0 && opcio3 === 0) || (opcio1 === 0 && opcio2 === 0 && opcio3 !== 0)) {
                alerta("Cal seleccionar almenys dues opcions de dies preferents")
                return
            }
            if (parseInt(comanda.dies) > 1 && opcio1 === 0 && opcio2 === 0 && opcio3 === 0) {
                alerta("Si vol regar més d'un dia cal seleccionar els dies que vol regar")
                return
            }
            if (((opcio1 === opcio2 && opcio1 !== 0) || (opcio1 === opcio3 && opcio1 !== 0) || (opcio2 === opcio3 && opcio2 !== 0))) {
                alerta("Cal seleccionar opcions diferents")
                return
            }
        }
        const method = accio === "crear" ? 'POST' : 'PUT'
        const url = method === 'POST' ? `/comanda/` : `/comanda/` + comanda.codi_solicitud + `/` + comanda.codi_comanda;
        const data = {
            codi_comuner: comanda.comuner.codi_comuner,
            tipus_aigua: comanda.tipusAigua.tipus_aigua,
            aCompteOExtra: comanda.aCompteOExtra !== null ? comanda.aCompteOExtra : 0,
            codi_presa: comanda.presa !== null ? comanda.presa.codi_presa : -1,
            codi_agrupacio: comanda.agrupacio !== null ? comanda.agrupacio.codi_agrupacio : null,
            hores: comanda.hores,
            mtn: comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null ? parseInt(document.getElementById("horariSelect").value) : null,
            dies_a_repartir: comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null ? parseInt(comanda.dies) : 0,
            opcio_1: comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null ? opcio1 : 0,
            opcio_2: comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null ? opcio2 : 0,
            opcio_3: comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null ? opcio3 : 0,
            comuner_junt: comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null ? document.getElementById("regarAmbComuner").value : "",
            observacions: (comanda.tipusAigua.tipus_aigua === 1 || comanda.tipusAigua.tipus_aigua > 1000) && comanda.agrupacio === null ? document.getElementById("observacions").value : ""
        }

        var botoGuardarComanda = document.getElementById("botoGuardarComanda")
        botoGuardarComanda.disabled = true

        instancePromise.then(instance => {
            instance({
                method,
                url,
                data,
                headers: {
                    'Authorization': 'Bearer ' + cookies.auth_token
                }
            })
                .then(() => {
                    botoGuardarComanda.disabled = false
                    const message = method === 'POST' ? 'Creada!' : 'Actualitzada!';
                    Swal.fire(
                        message,
                        `La teva comanda s'ha ${method === 'POST' ? 'creat' : 'actualitzat'} correctament`,
                        'success'
                    )
                        .then(() => {
                            navigate("/comandes")
                        })
                })
                .catch(error => {
                    botoGuardarComanda.disabled = false
                    handleError(error, navigate)
                });
        });
    }

    return (
        <section className="content">
            <div className="card card-primary card-outline">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <div><b>Comuner</b></div>
                            {accio === "crear" ? (
                                <>
                                    <select id="personaSelect" className="form-control-edited" onChange={changePersona} required title={invalidSelectMessage} onInvalid={handleInvalidSelect}>
                                        <option value="" hidden>Seleccionar un comuner</option>
                                        {personesDisponibles.map((personaDisponible, index) => (
                                            <option key={index} value={personaDisponible.comuner.codi_comuner}>{personaDisponible.persona.nom + " " + personaDisponible.persona.cognom1 + " " + personaDisponible.persona.cognom2 + " (" + personaDisponible.comuner.codi_comuner + ")"}</option>
                                        ))}
                                    </select>
                                </>
                            ) : (
                                <div>{comanda.persona.nom + " " + comanda.persona.cognom1 + " " + comanda.persona.cognom2 + " (" + comanda.comuner.codi_comuner + ")"}</div>
                            )}
                        </div>
                        {comanda.comuner.codi_comuner !== null ? (
                            <div id="tipusAigua" className="mb-3 mt-3">
                                <div><b>Tipus de reg</b></div>
                                {accio === "crear" ? (
                                    <select id="aiguaSelect" className="form-control-edited" onChange={changeTipusAigua} required title={invalidSelectMessage} onInvalid={handleInvalidSelect}>
                                        <option value="" hidden>Seleccionar un tipus de reg</option>
                                        {tipusAiguaDisponibles.map((tipusAiguaDisponible, index) => (
                                            <option key={index} value={tipusAiguaDisponible.tipus_aigua}>{tipusAiguaDisponible.descripcio}</option>
                                        ))}
                                    </select>
                                ) : (<>
                                    {comanda.aCompteOExtra === 2 ? (
                                        <div>{comanda.tipusAigua.descripcio + " (A compte)"}</div>
                                    ) : (
                                        <>
                                            {comanda.aCompteOExtra === 3 ? (
                                                <div>{comanda.tipusAigua.descripcio + " (Extra)"}</div>
                                            ) : (
                                                <div>{comanda.tipusAigua.descripcio}</div>)}
                                        </>)
                                    }
                                </>
                                )}
                            </div>
                        ) : (<></>)}
                        {comanda.tipusAigua.tipus_aigua > 1000 && comanda.comuner.codi_comuner !== null && accio === "crear" ? (
                            <div id="aCompteOExtra" className="mb-3 mt-3">
                                <div><b>A compte o extra?</b></div>
                                <select id="aCompteOExtraSelect" className="form-control-edited" onChange={changeACompteOExtra} required title={invalidSelectMessage} onInvalid={handleInvalidSelect}>
                                    <option value="" hidden>Seleccionar a compte o extra</option>
                                    <option value="2">A compte</option>
                                    {permetExtra ? (
                                        <option value="3">Extra</option>
                                    ) : (<></>)}
                                </select>
                            </div>
                        ) : (<></>)}
                        {(comanda.tipusAigua.tipus_aigua === 1 || (comanda.tipusAigua.tipus_aigua >= 11 && comanda.tipusAigua.tipus_aigua <= 15) || (comanda.tipusAigua.tipus_aigua > 1000 && (comanda.aCompteOExtra === 2 || comanda.aCompteOExtra === 3))) && comanda.comuner.codi_comuner !== null ? (
                            <>
                                <div className="mb-3 mt-3">
                                    <div><b>Presa</b></div>
                                    {accio === 'crear' ? (
                                        <select id="presaSelect" className="form-control-edited" onChange={changePresa} required title={invalidSelectMessage} onInvalid={handleInvalidSelect}>
                                            <option value="" hidden>Seleccionar una presa</option>
                                            {presesDisponibles.map((presaDisponible, index) => (
                                                <option key={index} value={presaDisponible.codi_presa}>{presaDisponible.nom}</option>
                                            ))}
                                        </select>
                                    ) : (
                                        <div>{comanda.presa.nom}</div>
                                    )}
                                </div>
                                <div id="agrupacio" className="mb-3 mt-3">
                                    {accio === 'crear' || accio === 'editar' ? (
                                        <>
                                            {agrupacionsDisponibles.length !== 0 && comanda.presa !== null ? (
                                                <>
                                                    <div><b>Agrupació</b></div>
                                                    <select id="agrupacioSelect" className="form-control-edited" onChange={changeAgrupacio}>
                                                        <option value="">Fora de l'agrupació</option>
                                                        {agrupacionsDisponibles.map((agrupacioDisponible, index) => (
                                                            <option key={index} value={agrupacioDisponible.codi_agrupacio}>{agrupacioDisponible.nom_agrupacio}</option>
                                                        ))}
                                                    </select>
                                                </>
                                            ) : (<></>)}
                                        </>
                                    ) : (
                                        <>
                                            {comanda.agrupacio !== null ? (
                                                <>
                                                    <div><b>Agrupació</b></div>
                                                    <div>{comanda.agrupacio.nom_agrupacio}</div>
                                                </>
                                            ) : (<></>)}
                                        </>
                                    )}
                                </div>

                            </>
                        ) : (<></>)}
                        {((comanda.tipusAigua.tipus_aigua !== 7 && comanda.tipusAigua.tipus_aigua !== null && comanda.presa !== null) || comanda.tipusAigua.tipus_aigua === 7) && comanda.comuner.codi_comuner !== null ? (
                            <div id="hores" className="mb-3 mt-3">
                                <div><b>Hores {comanda.tipusAigua.tipus_aigua === 1 || comanda.tipusAigua.tipus_aigua === 7 ? "setmanals" : ""}</b></div>
                                {accio === 'crear' || accio === 'editar' ? (
                                    <input type="number" className="form-control-edited" min="1" id="nHores" onChange={changeHores} required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidNumber(e) }} onInvalid={handleInvalidNumber}></input>
                                ) : (
                                    <div>{comanda.hores}</div>
                                )}
                            </div>
                        ) : (<></>)}
                        {comanda.tipusAigua.tipus_aigua === 1 && comanda.hores !== null && comanda.agrupacio === null && comanda.presa !== null && comanda.comuner.codi_comuner !== null ? (
                            <>
                                <div id="horari" className="mb-3 mt-3">
                                    {accio === 'crear' || accio === 'editar' ? (
                                        <>
                                            <div><b>Moment del dia</b></div>
                                            <em><div style={{ fontSize: '0.85em', color: '#555555' }}>En cas de seleccionar de dia se li aplicarà el càrrec corresponent</div></em>
                                            <select id="horariSelect" className="form-control-edited">
                                                <option value="0">Indiferent</option>
                                                <option value="1">De nit</option>
                                                <option value="2">De dia, preferiblement de matí</option>
                                                <option value="4">De dia, preferiblement de tarda</option>
                                                <option value="8">De dia</option>
                                            </select>
                                        </>
                                    ) : (<>
                                        {comanda.mtn !== 0 ? (
                                            <>
                                                <div><b>Moment del dia</b></div>
                                                <div>{translateHorari(comanda.mtn)}</div>
                                            </>
                                        ) : (<></>)}
                                    </>
                                    )}
                                </div>
                                <div id="dies" className="mb-3 mt-3">
                                    {accio === 'crear' || accio === 'editar' ? (
                                        <>
                                            <div><b>Dies a repartir</b></div>
                                            <em><div style={{ fontSize: '0.85em', color: '#555555' }}>En cas d'indicar més d'1 dia se li aplicarà el càrrec corresponent</div></em>
                                            <input type="number" className="form-control-edited" value={comanda.dies} max="7" min="1" id="nDies" onChange={changeDies} required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidNumber(e) }} onInvalid={handleInvalidNumber}></input>
                                        </>
                                    ) : (<>
                                        {comanda.dies > 1 ? (
                                            <>
                                                <div><b>Dies a repartir</b></div>
                                                <div>{comanda.dies}</div>
                                            </>
                                        ) : (<></>)}
                                    </>

                                    )}
                                </div>
                                <div id="preferencies" className="mb-3 mt-3">
                                    {accio === 'crear' || accio === 'editar' ? (
                                        <>
                                            <div><b>Dies preferents</b></div>
                                            <em><div style={{ fontSize: '0.85em', color: '#555555' }}>{comanda.dies === null ? "Primerament, cal indicar el nombre de dies" : comanda.dies === 1 ? "En cas de selecció (almenys dues opcions) se li aplicarà el càrrec corresponent" : "Cal seleccionar almenys dues opcions de dies preferents"}</div></em>
                                            {createCheckGroups(1)}
                                            {createCheckGroups(2)}
                                            {createCheckGroups(3)}
                                        </>
                                    ) : (
                                        <>
                                            {translatePreferencies(comanda.opcio1, comanda.opcio2, comanda.opcio3) !== "" ? (
                                                <>
                                                    <div><b>Dies preferents</b></div>
                                                    <div>{translatePreferencies(comanda.opcio1, comanda.opcio2, comanda.opcio3)}</div>
                                                </>
                                            ) : (<></>)}
                                        </>

                                    )}
                                </div>
                            </>) : (<></>)}
                        {comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null && comanda.hores !== null && comanda.presa !== null && comanda.comuner.codi_comuner !== null ? (
                            <div id="comunerJunt" className="mb-3 mt-3">
                                {accio === 'crear' || accio === 'editar' ? (
                                    <>
                                        <div><b>Comuner amb qui vol regar (opcional)</b></div>
                                        <input type="text" id="regarAmbComuner" className="form-control-edited"></input>
                                    </>
                                ) : (
                                    <>
                                        {comanda.comunerJunt !== null && comanda.comunerJunt !== "" ? (
                                            <>
                                                <div><b>Comuner amb qui vol regar</b></div>
                                                <div>{comanda.comunerJunt}</div>
                                            </>
                                        ) : (<></>)}
                                    </>
                                )}
                            </div>
                        ) : (<></>)}
                        {(comanda.tipusAigua.tipus_aigua === 1 || comanda.tipusAigua.tipus_aigua > 1000) && comanda.agrupacio === null && comanda.presa !== null && comanda.hores !== null && comanda.comuner.codi_comuner !== null ? (
                            <div id="campObservacions" className="mb-3 mt-3">
                                {accio === 'crear' || accio === 'editar' ? (
                                    <>
                                        <div><b>Observacions (opcional)</b></div>
                                        <textarea id="observacions" className="form-control-edited"></textarea><br />
                                    </>
                                ) : (
                                    <>
                                        {comanda.observacions !== null && comanda.observacions !== "" ? (
                                            <>
                                                <div><b>Observacions</b></div>
                                                <div>{comanda.observacions}</div>
                                            </>
                                        ) : (<></>)}
                                    </>
                                )}
                            </div>
                        ) : (<></>)}
                        {accio === 'crear' || accio === 'editar' ? (
                            <>
                                {
                                    comanda.tipusAigua.tipus_aigua === 1 && comanda.agrupacio === null && comanda.hores !== null && (accio === 'crear' || accio === 'editar') ? (
                                        <>
                                            {preu !== 0 && comanda.hores !== null && comanda.presa !== null && comanda.comuner.codi_comuner !== null ? (
                                                <div className="col-xl-7 col-12 mb-3 mt-4 row justify-content-end">
                                                    <div><b>Total</b></div>
                                                    <div className="ml-5">{preu} €</div>
                                                </div>
                                            ) : (<></>)}
                                            <div className="col-xl-7 col-12 mt-3 text-right">
                                                {comanda.hores !== null && comanda.presa !== null && comanda.comuner.codi_comuner !== null ? (
                                                    <button id="botoGuardarComanda" className="btn btn-primary">{accio === 'crear' ? "Realitzar Comanda" : "Guardar"}</button>
                                                ) : (<></>)}
                                                <button type="button" className="btn btn-danger ml-3" onClick={() => navigate(-1)}>Cancel·lar</button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {preu !== 0 && ((comanda.tipusAigua.tipus_aigua !== 7 && comanda.tipusAigua.tipus_aigua !== null && comanda.presa !== null) || comanda.tipusAigua.tipus_aigua === 7) && comanda.hores !== null && comanda.comuner.codi_comuner !== null ? (
                                                <div className="form-control-edited mb-3 mt-4 row justify-content-end">
                                                    <div><b>Total</b></div>
                                                    <div className="ml-5">{preu} €</div><br />
                                                </div>
                                            ) : (<></>)}
                                            <div className="form-control-edited mt-3 text-right">
                                                {accio === 'detalls' && comanda.modificable ? (<button className="btn btn-primary" onClick={() => navigate("/comandes/editar", { state: { comanda: comanda } })}>Modificar</button>) : (<></>)}
                                                {((comanda.tipusAigua.tipus_aigua !== 7 && comanda.tipusAigua.tipus_aigua !== null && comanda.presa !== null) || comanda.tipusAigua.tipus_aigua === 7) && comanda.hores !== null && comanda.comuner.codi_comuner !== null ? (
                                                    <button id="botoGuardarComanda" className="btn btn-primary">{accio === 'crear' ? "Realitzar Comanda" : "Guardar"}</button>
                                                ) : (<></>)}
                                                <button type="button" className="btn btn-danger ml-3" onClick={() => navigate(-1)}>Cancel·lar</button>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        ) : (
                            <div className="form-control-edited mt-4 text-right">
                                {comanda.modificable ? (<button className="btn btn-primary" onClick={() => navigate("/comandes/editar", { state: { comanda: comanda } })}>Modificar</button>) : (<></>)}
                                <button type="button" className="btn btn-danger ml-3" onClick={() => navigate(-1)}>Enrere</button>
                            </div>
                        )}
                    </form>
                </div >
            </div>
        </section>
    )
}