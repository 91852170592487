import React from 'react';

export default function AvisLegal() {
    document.title = "Avís Legal - Pantà de Riudecanyes"
    return (
        <div>
            <section className="content">
                <div className="card">
                    <div className="card-header">
                        <h1 className="entry-title" itemprop="headline">Avís legal</h1>
                    </div>
                    <div className="card-body">
                        <h3>DADES IDENTIFICATIVES DEL TITULAR DEL WEB</h3>
                        <p>En compliment  del deure d'informació estipulat en l'article 10 de la Llei 34/2002 d' 11 de juliol dels Serveis de la Societat de la Informació i del Comerç Electrònic, <strong><em>Comunitat de Regants del Pantà de Riudecanyes (CRPR)</em></strong>, amb CIF G43016989, d'ara endavant <strong>Pantà de Riudecanyes</strong>, en qualitat de titular del web <strong>pantaderiudecanyes.cat</strong>, amb adreça a Carrer de Vallroquetes, 2, codi postal 43201 de Reus, Tarragona, direcció de correu electrònic <a href="mailto:c%6frreu@pa%6etader%69udecan%79e%73.%63at">correu@pantaderiudecanyes.cat</a> y teléfono 977 340 786, procedeix a comunicar-los la informació que conforma i regula les condicions d'ús d'aquesta pàgina, les limitacions de responsabilitat i les obligacions que els usuaris de la web que és publicat sota el domini <strong><i>pantaderiudecanyes.cat</i></strong>, assumeixen i es comprometen a respectar.</p>
                        <h3>CONDICIONS D'ÚS</h3>
                        <p>La utilització de <strong>pantaderiudecanyes.cat</strong> atorga la condició d'<strong>Usuari</strong> de <strong>pantaderiudecanyes.cat</strong>, sigui persona física o jurídica i obligatòriament implica l'acceptació completa, plena i sense reserves de totes i cadascuna de les clàusules i condicions generals incloses en l'Avís Legal. Si l'<strong>Usuari</strong> no estigués conforme amb elles, s'abstindrà d'utilitzar <strong>pantaderiudecanyes.cat</strong>. Aquest Avís Legal està subjecte a canvis i actualitzacions pel que la versió publicada per <strong>Pantà de Riudecanyes</strong> pot ser diferent en cada moment en què l'<strong>Usuari</strong> accedeixi al Portal. Per tant, l'<strong>Usuari</strong> ha de llegir l'Avís Legal en totes i cadascuna de les ocasions que accedeixi a <strong>pantaderiudecanyes.cat</strong>.</p>
                        <p>A través de <strong>pantaderiudecanyes.cat</strong>, <strong>Pantà de Riudecanyes</strong><b> </b>facilita a l'<strong>Usuari</strong> l'accés i utilització de diversos Continguts publicats per mitjà d'Internet per <strong>Pantà de Riudecanyes</strong> o per tercers autoritzats.</p>
                        <p>L'<strong>Usuari</strong> està obligat i es compromet a utilitzar <strong>pantaderiudecanyes.cat</strong> i els continguts conforme a la legislació vigent, l'Avís Legal i qualsevol altre avís o instrucció que es posin en coneixement, ja sigui per mitjà d'aquest mateix Avís Legal o en qualsevol lloc dels Continguts que conformen <strong>pantaderiudecanyes.cat</strong>, com són les normes de convivència, la moral i els bons costums generalment acceptades. En aquests termes, l'<strong>Usuari</strong> s'obliga i compromet a NO  emprar qualsevol dels Continguts amb fins i efectes il·lícits, prohibits en l'Avís Legal o per la legislació vigent, contrari als drets i interessos de tercers o que de qualsevol manera puguin fer mal, inutilitzar, sobrecarregar, deteriorar o impedir el normal ús dels Continguts, els equips informàtics o els documents, arxius i tota classe de continguts magatzemats en qualsevol equip informàtic propi o contractats per <strong>Pantà de Riudecanyes</strong>, d'un altre <strong>USUARI</strong> o de qualsevol usuari d'Internet (hardware i software).</p>
                        <p>L'<strong>Usuari</strong> s'obliga i compromet a no transmetre, difondre o posar a disposició de tercers qualsevol mena de material que formi part de <strong>pantaderiudecanyes.cat</strong>, com informacions, textos, dades, continguts, missatges, gràfiques, dibuixos, arxius de so i/o imatge, fotografies, gravacions, software, logos, marques, icones, tecnologia, enllaços, disseny gràfic i codis de fons, o qualsevol altre material al qual tingués accés en la seva condició d'<strong>Usuari</strong> de <strong>pantaderiudecanyes.cat</strong>, sense que aquesta enumeració tingui caràcter restringit. Així mateix, conforme a tot plegat, l'<strong>Usuari</strong> no podrà:</p>
                        <ul>
                            <li>Reproduir, copiar, distribuir, posar a disposició o de qualsevol altra manera comunicar públicament, transformar o modificar els Continguts, excepte que tingui autorització escrita i explícita de <strong>Pantà de Riudecanyes</strong>, que és titular d'aquests drets, o bé que estigui legalment permès.</li>
                            <li>Suprimir, manipular o de qualsevol manera alterar el “copyright” i altres dades identificatives de la reserva de drets de <strong>Pantà de Riudecanyes</strong> o dels seus titulars, de les empremptes i/o identificadors digitals, marques d'aigua o qualsevol altre medi tècnic establerts pel seu reconeixement.</li>
                        </ul>
                        <p>L'<strong>Usuari</strong> s'abstindrà d'obtenir i d'intentar accedir als Continguts utilitzant mitjans o procediments diferents dels que, segons els casos, s'hagin posat a la seva disposició per aquesta finalitat, o s'hagin indicat per aquest fi en les pàgines web on es trobin els Continguts, o en general, els que s'emprin habitualment a Internet per aquest efecte sempre que no provoquin cap risc de dany o inutilització de la web <strong>pantaderiudecanyes.cat</strong>, i/o dels Continguts.</p>
                        <h3>PROPIETAT INTEL·LECTUAL</h3>
                        <p>Totes les marques, noms comercials o signes distintius de qualsevol classe que apareixen a <strong>pantaderiudecanyes.cat</strong> són propietat de <strong>Pantà de Riudecanyes</strong> o si no pot ser, dels seus respectius propietaris, sense que pugui entendre’s en cap  cas que l’ús o accés al Portal i/o als Continguts  doni a l’<strong>Usuari</strong> cap dret sobre les esmentades marques, noms comercials i/o signes distintius i sense que es puguin entendre cedits a l'<strong>Usuari</strong>, com tampoc cap dret d’explotació que existeixin o puguin existir sobre aquests Continguts. De la mateixa manera els Continguts són propietat intel·lectual de <strong>Pantà de Riudecanyes</strong>, o de tercers en el seu cas, per tant, els drets de Propietat Intel·lectual són de titularitat de <strong>Pantà de Riudecanyes</strong> o de tercers a qui se’ls hagi autoritzat el seu ús, a qui correspon l’exercici exclusiu dels drets d’explotació dels mateixos en qualsevol manera, i en especial, els drets de reproducció, distribució, comunicació pública i transformació. L’ús no autoritzat de la informació continguda en aquesta Web, així com la lesió dels drets de la Propietat Intel·lectual o Industrial de <strong>Pantà de Riudecanyes</strong> o de tercers inclosos en <strong>pantaderiudecanyes.cat</strong> que hagin cedit continguts donarà lloc a les responsabilitats legalment establertes.</p>
                        <h3>DISPONIBILITAT DE PANTADERIUDECANYES.CAT</h3>
                        <p><strong>Pantà de Riudecanyes</strong> no garanteix la inexistència d'interrupcions o errades en l’accés a <strong>pantaderiudecanyes.cat</strong>, als seus continguts, ni que aquests es trobin actualitzats, encara que desenvoluparà esforços, per intentar evitar-los,  arranjar-los o actualitzar-los. Per tant, <strong>Pantà de Riudecanyes</strong> no es responsabilitza dels danys o perjudicis de qualsevol classe produïts en l'<strong>Usuari</strong> provocats per errades o desconnexions en les xarxes de telecomunicacions que produeixen la suspensió, cancel·lació o interrupció del servei del portal durant la prestació del mateix o amb caràcter previ.</p>
                        <p><strong>Pantà de Riudecanyes</strong> exclou, excepte les contemplades en la legislació vigent, qualsevol responsabilitat pels danys i perjudicis de tota naturalesa que esdevinguin de la falta de disponibilitat, continuïtat o qualitat del funcionament de <strong>pantaderiudecanyes.cat</strong> i dels Continguts, a l’incompliment de l’expectativa d’utilitat que l'<strong>USUARI</strong> hagués pogut atribuir a <strong>pantaderiudecanyes.cat</strong> i als Continguts.</p>
                        <p>La funció dels Hiperenllaços que apareguin en aquesta Web és exclusivament la d’informar a l'<strong>Usuari</strong> sobre  l’existència d’altres Web que tenen informació sobre la matèria. Aquests Hiperenllaços no constitueixen suggeriment ni recomanació.</p>
                        <p><strong>Pantà de Riudecanyes</strong>  no es fa responsable dels continguts de les pàgines enllaçades, del funcionament o ús dels Hiperenllaços ni del resultat dels mateixos, ni garanteix l’absència de virus o altres elements en els mateixos que puguin provocar alteracions en el sistema informàtic (hardware i software), els documents o els fitxers de l'<strong>Usuari</strong>, excloent qualsevol responsabilitat pels danys de qualsevol classe causats a l'<strong>Usuari</strong> per aquest motiu.</p>
                        <p>L'accés a <strong>pantaderiudecanyes.cat</strong> no implica l'obligació per part de <strong>Pantà de Riudecanyes</strong> de controlar l'absència de virus, cucs informàtics o qualsevol altre element informàtic danyat. Correspon a l'<strong>Usuari</strong>, en tot cas, la disponibilitat d’eines adequades per la detecció i desinfecció de programes informàtics danyats, pel que <strong>Pantà de Riudecanyes</strong> no es fa responsable de les possibles errades de seguretat que es produeixin durant la prestació del servei de <strong>pantaderiudecanyes.cat</strong>, ni dels possibles danys que puguin donar-se al sistema informàtic de l'<strong>Usuari</strong> o de tercers (hardware i software), els fitxers o documents emmagatzemats en el mateix, com a conseqüència de la presència de virus en l’ordinador de l'<strong>Usuari</strong> emprat per la connexió als serveis i continguts de la Web, d'un mal funcionament del navegador o de l'ús de versions no actualitzades d'aquest.</p>
                        <p>La prestació del servei de <strong>pantaderiudecanyes.cat</strong> i dels Continguts té, en principi, una durada indefinida. Tanmateix, <strong>Pantà de Riudecanyes</strong>, qi/o de qualsevol dels Continguts en qualsevol moment. Quan sigui possible, <strong>Pantà de Riudecanyes</strong> advertirà prèviament l'acabament o suspensió de <strong>pantaderiudecanyes.cat</strong>.</p>
                        <h3>QUALITAT DE PANTADERIUDECANYES.CAT</h3>
                        <p>Donat l’entorn dinàmic i canviant de la informació i dels serveis que se subministren a través de <strong>pantaderiudecanyes.cat</strong>, <strong>Pantà de Riudecanyes</strong> posa èmfasi, però no garanteix la completa veracitat, exactitud, fiabilitat, utilitat i/o actualitat dels Continguts. La informació de les pàgines d’aquest Portal només té caràcter informatiu, consultiu, divulgatiu i publicitari. En cap cas, ofereixen o tenen caràcter de compromís vinculant o contractual.</p>
                        <h3>LIMITACIÓ DE RESPONSABILITAT</h3>
                        <p><strong>Pantà de Riudecanyes</strong> exclou de tota responsabilitat per les decisions que l'<strong>Usuari</strong> pugui prendre basant-se en aquesta informació, així com per les possibles errades tipogràfiques que puguin tenir els documents i gràfics de <strong>pantaderiudecanyes.cat</strong>. La informació està sotmesa a possibles canvis periòdics sense previ avís del seu contingut per ampliació, millora, correcció o actualització dels Continguts.</p>
                        <h3>NOTIFICACIONS</h3>
                        <p>Totes les notificacions i comunicacions per part de <strong>Pantà de Riudecanyes</strong> a l'<strong>Usuari</strong> efectuades per qualsevol mitjà es consideraran eficaces a tots els efectes.</p>
                        <h3>JURISDICCIÓ</h3>
                        <p>Per totes les qüestions que es plantegin sobre la interpretació, aplicació i compliment d’aquest Avís Legal, així com de les reclamacions que puguin derivar-se del seu ús, totes les parts que intervenen se sometent als Jutges i Tribunals de la província de Tarragona, renunciant de forma expressa a qualsevol  fur o jurisdicció que pugui correspondre-li.</p>
                        <h3>LEGISLACIÓ APLICABLE</h3>
                        <p>El present Avís Legal es regeix per la normativa espanyola vigent. </p>
                    </div>
                </div>
            </section>
        </div>
    );
}