import React from 'react';
import { useNavigate } from "react-router-dom"
import instancePromise from '../axiosInstance';
import { useCookies } from 'react-cookie';
import { handleError, handleInvalidInput, invalidInputMessage } from '../Global'

export default function AfegirComuner() {
    const navigate = useNavigate();
    const [cookies] = useCookies(['auth_token']);

    document.title = "Afegir comuner - Pantà de Riudecanyes"

    function handleSubmit(event) {
        event.preventDefault();

        var btnAfegir = document.getElementById("btnAfegir")
        btnAfegir.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/usuari/afegir_persona/`,
                    {
                        nif: document.getElementById("dni").value,
                        data_naix: document.getElementById("naixement").value,
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + cookies.auth_token
                        }
                    })
                .then(() => {
                    btnAfegir.disabled = false
                    navigate(-1)
                })
                .catch(error => {
                    btnAfegir.disabled = false
                    handleError(error, navigate)
                });
        });
    }


    return (
        <main className="hold-transition register-page">
            <div className="register-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                    </div>
                    <div className="card-body">

                        <form onSubmit={handleSubmit}>

                            <h5>Dades del comuner a gestionar</h5>

                            <div className="input-group mb-3">
                                <input type="text" id="dni" className="form-control" placeholder="DNI" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-id-card"></span>
                                    </div>
                                </div>
                            </div>

                            <p className="mb-0">Data de naixement</p>

                            <div className="input-group mb-3">
                                <input type="date" id="naixement" className="form-control" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user"></span>
                                    </div>
                                </div>
                            </div>

                            <div className="text-right">
                                <button type="submit" id="btnAfegir" className="btn btn-primary pl-4 pr-4">Afegir</button>
                                <button type="button" className="btn btn-danger ml-3" onClick={() => navigate(-1)}>Cancel·lar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}