import Swal from 'sweetalert2'

export const invalidInputMessage = "Cal omplir aquest camp"
const invalidContrasenyaMessage = "La contrasenya ha de tenir mínim 8 caràcters"
const invalidNumberMessage = "Cal escriure un número"
const invalidMinimumMessage = "El valor ha de ser major o igual que "
const invalidMaximumMessage = "El valor ha de ser menor o igual que "
export const invalidSelectMessage = "Cal seleccionar un element de la llista"
const invalidCheckboxMessage = "Cal marcar aquesta casella per continuar"

export function handleInvalidInput(event) {
    const input = event.target;

    if (input.validity.valueMissing) {
        input.setCustomValidity(invalidInputMessage)
    } else {
        input.setCustomValidity("");
    }

    reportValidity(input)
}

export function handleInvalidContrasenya(event) {
    const input = event.target;

    if (input.validity.valueMissing) {
        input.setCustomValidity(invalidInputMessage)
    } else if (input.validity.patternMismatch) {
        input.setCustomValidity(invalidContrasenyaMessage);
    } else {
        input.setCustomValidity("");
    }

    reportValidity(input)
}

export function handleInvalidNumber(event) {
    const input = event.target;

    if (input.validity.badInput) {
        input.setCustomValidity(invalidNumberMessage)
    } else if (input.validity.valueMissing) {
        input.setCustomValidity(invalidInputMessage)
    } else if (input.validity.rangeUnderflow) {
        input.setCustomValidity(invalidMinimumMessage + input.min);
    } else if (input.validity.rangeOverflow) {
        input.setCustomValidity(invalidMaximumMessage + input.max);
    } else {
        input.setCustomValidity("");
    }

    reportValidity(input)
}

export function handleInvalidSelect(event) {
    const input = event.target;

    if (input.validity.valueMissing) {
        input.setCustomValidity(invalidSelectMessage)
    } else {
        input.setCustomValidity("");
    }

    reportValidity(input)
}

export function handleInvalidEmail(event) {
    const input = event.target;

    if (input.validity.valueMissing) {
        input.setCustomValidity(invalidInputMessage);
    } else if (!input.value.includes('@')) {
        input.setCustomValidity('Cal incloure una \'@\' en la direcció de correu electrònic');
    } else if (input.value.split('@')[1].length === 0) {
        input.setCustomValidity('Cal escriure una part després de \'@\'');
    } else {
        input.setCustomValidity('');
    }

    reportValidity(input)
}

export function handleInvalidCheckbox(event) {
    const input = event.target;

    if (!input.checked) {
        input.setCustomValidity(invalidCheckboxMessage)
    } else {
        input.setCustomValidity("");
    }

    reportValidity(input)
}

function reportValidity(input) {
    if (!input.dataset.isHandlingInvalid) {
        input.dataset.isHandlingInvalid = true;
        input.reportValidity();
    }
}


export function alerta(message) {
    var Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000
    });
    Toast.fire({
        icon: 'error',
        title: message
    })
}

export function success(message) {
    var Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000
    });
    Toast.fire({
        icon: 'success',
        title: message
    })
}

export function handleError(error, navigate) {
    if (error.code === "ERR_NETWORK") {
        alerta("Error de connexió. Intenta-ho més tard")
    } else {
        if (error.response.status === 401) {
            navigate("/login")
        } else {
            alerta(error.response.data);
        }
    }
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}