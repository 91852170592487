import React from 'react';
import instancePromise from './axiosInstance';
import { useCookies } from 'react-cookie';
import { invalidInputMessage, handleInvalidInput, alerta, success, parseJwt } from './Global'

export default function ContactUs() {
    const [cookies] = useCookies(['auth_token']);

    document.title = "Contacte - Pantà de Riudecanyes"

    function sendEmail(event) {
        event.preventDefault()

        var inputName = document.getElementById("inputName")
        var inputEmail = document.getElementById("inputEmail")
        var inputSubject = document.getElementById("inputSubject")
        var inputMessage = document.getElementById("inputMessage")

        var btnEnviar = document.getElementById("btnEnviar")
        btnEnviar.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/usuari/send_feedback_email`,
                    {
                        name: inputName.value,
                        email: inputEmail.value,
                        subject: inputSubject.value,
                        message: inputMessage.value
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + cookies.auth_token
                        }
                    })
                .then(() => {
                    btnEnviar.disabled = false

                    success("Missatge enviat")

                    inputSubject.value = ""
                    inputMessage.value = ""
                }).catch(() => {
                    btnEnviar.disabled = false
                    alerta("No s'ha pogut enviar el missatge")
                });
        });
    }


    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Contacte</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item active">Contacte</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="card">
                    <div className="card-body row">
                        <div className="col-sm-5 text-center d-flex align-items-center justify-content-center">
                            <div>
                                <h2>Pantà de Riudecanyes</h2>
                                <h4>Comunitat de Regants</h4>
                                <p><strong>Oficines</strong><br /><a href="tel:+34977340786" target="_blank" rel="noreferrer">977 340 786</a></p>
                                <p><strong>WhatsApp</strong><br /><a href="https://api.whatsapp.com/send/?phone=34646831753&amp;text&amp;app_absent=0" target="_blank" rel="noreferrer">646 831 753</a></p>
                                <p><strong>Emergències</strong><br /><a href="tel:+34659634585" target="_blank" rel="noreferrer">659 634 585</a></p>
                            </div>
                        </div>

                        <div className="col-sm-7">
                            <form onSubmit={sendEmail}>
                                <div className="form-group">
                                    <label htmlFor="inputName">Nom</label>
                                    <input type="text" id="inputName" defaultValue={parseJwt(cookies.auth_token).unique_name} className="form-control" required title={invalidInputMessage} onInput={e => e.target.setCustomValidity('')} onInvalid={handleInvalidInput} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputEmail">Correu</label>
                                    <input type="email" id="inputEmail" defaultValue={parseJwt(cookies.auth_token).email} className="form-control" required title={invalidInputMessage} onInput={e => e.target.setCustomValidity('')} onInvalid={handleInvalidInput} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputSubject">Assumpte</label>
                                    <input type="text" id="inputSubject" className="form-control" required title={invalidInputMessage} onInput={e => e.target.setCustomValidity('')} onInvalid={handleInvalidInput} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="inputMessage">Missatge</label>
                                    <textarea id="inputMessage" className="form-control" rows="4" required title={invalidInputMessage} onInput={e => e.target.setCustomValidity('')} onInvalid={handleInvalidInput} />
                                </div>
                                <div className="form-group text-right">
                                    <input type="submit" id="btnEnviar" className="btn btn-primary" value="Enviar" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}