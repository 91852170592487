import React, { useEffect, useState } from 'react';
import instancePromise from '../axiosInstance';
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"
import { handleError, invalidInputMessage, handleInvalidInput, invalidSelectMessage, handleInvalidSelect, handleInvalidNumber } from '../Global'

export default function NouCertificatDret() {
    const [cookies] = useCookies(['auth_token']);
    const navigate = useNavigate();
    const anyActual = new Date().getFullYear()

    document.title = "Nou certificat de dret - Pantà de Riudecanyes"

    var [personesDisponibles, setPersonesDisponibles] = useState([])
    var [tipusCertificat, setTipusCertificat] = useState("")
    var [mostrarAnyValoracio, setMostrarAnyValoracio] = useState(false)

    useEffect(loadPersones, [cookies, navigate])
    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const alertUser = (e) => {
        e.preventDefault();
        if (document.getElementById("botoCrear"))
            e.returnValue = "";
    };

    function changeTipus(event) {
        setTipusCertificat(event.target.value)
    }

    function loadPersones() {
        instancePromise.then(instance => {
            instance
                .get(`/usuari/persona-amb-drets`, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setPersonesDisponibles(response.data)
                    if (response.data.length === 0) {
                        Swal.fire({
                            title: 'Per sol·licitar un nou certificat de dret cal afegir un comuner',
                            icon: 'info',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Afegir comuner'
                        })
                        .then(() => {
                            navigate("/afegir-persona")
                        })
                    }
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }


    function handleSubmit(event) {
        event.preventDefault()

        var botoCrear = document.getElementById("botoCrear")
        botoCrear.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/certificat-dret`,
                    {
                        tipus_certificat: tipusCertificat,
                        nom: document.getElementById("nom").value,
                        cognoms: document.getElementById("cognoms").value,
                        dni: document.getElementById("dni").value,
                        codi_persona: parseInt(document.getElementById("personaSelect").value),
                        any_defuncio: tipusCertificat === '1' ? document.getElementById("anyDefuncio").value : null,
                        any_valoracio: tipusCertificat === '2' ? (mostrarAnyValoracio ? document.getElementById("anyValoracio").value : -1) : null
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + cookies.auth_token
                        }
                    })
                .then(() => {
                    botoCrear.disabled = false
                    Swal.fire(
                        'Creat!',
                        'Certificat de dret creat correctament',
                        'success'
                    )
                        .then(() => {
                            navigate(-1)
                        })
                })
                .catch(error => {
                    botoCrear.disabled = false
                    handleError(error, navigate)
                });
        });
    }

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Nou certificat de dret</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item"><a href="/certificats-drets">Certificats de drets</a></li>
                                <li className="breadcrumb-item active">Nou certificat de dret</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="card card-primary card-outline">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <div><b>Tipus de certificat</b></div>
                                <select id="tipusSelect" className="form-control-edited" onChange={changeTipus} required>
                                    <option value="" hidden>Seleccionar un tipus de certificat</option>
                                    <option value="1">Per tramitar una herència</option>
                                    <option value="2">De titularitat</option>
                                </select>
                            </div>
                            {tipusCertificat !== "" ? (
                                <>
                                    <h5>Dades del sol·licitant</h5>
                                    <div className="mb-2">
                                        <div><b>Nom</b></div>
                                        <input type="text" id="nom" className="form-control-edited" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                    </div>
                                    <div className="mb-2">
                                        <div><b>Cognoms</b></div>
                                        <input type="text" id="cognoms" className="form-control-edited" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                    </div>
                                    <div className="mb-4">
                                        <div><b>DNI</b></div>
                                        <input type="text" id="dni" className="form-control-edited" required title={invalidInputMessage} onInput={handleInvalidInput} onInvalid={handleInvalidInput} />
                                    </div>
                                    <div className="mb-4">
                                        <div><b>Persona</b></div>
                                        <select id="personaSelect" className="form-control-edited" required title={invalidSelectMessage} onChange={handleInvalidSelect} onInvalid={handleInvalidSelect}>
                                            <option value="" hidden>Seleccionar una persona</option>
                                            {personesDisponibles.map((personaDisponible, index) => (
                                                <option key={index} value={personaDisponible.codi_persona}>{personaDisponible.nom + " " + personaDisponible.cognom1 + " " + personaDisponible.cognom2}</option>
                                            ))}

                                        </select>
                                    </div>
                                    {tipusCertificat === "1" ? (
                                        <div className="mb-4">
                                            <div><b>Any de la defunció</b></div>
                                            <input type="number" min="1914" max={anyActual} id="anyDefuncio" className="form-control-edited" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidNumber(e) }} onInvalid={handleInvalidNumber} />
                                        </div>
                                    ) : (
                                        <>
                                            {tipusCertificat === "2" ? (
                                                <>
                                                    <div className="custom-control custom-checkbox mb-2">
                                                        <input type="checkbox" className="custom-control-input" id="chkAnyValoracio" onClick={() => setMostrarAnyValoracio(!mostrarAnyValoracio)} />
                                                        <label className="custom-control-label" htmlFor="chkAnyValoracio">Mostrar valor del dret</label>
                                                    </div>
                                                    {mostrarAnyValoracio ? (
                                                        <>
                                                            <div><b>Any de la valoració</b></div>
                                                            <input type="number" min="1979" max={anyActual} id="anyValoracio" className="form-control-edited" required />
                                                        </>
                                                    ) : (<></>)}
                                                </>
                                            ) : (<></>)}
                                        </>)}
                                </>
                            ) : (<></>)}
                            <div className="form-control-edited mt-4 text-right">
                                {tipusCertificat !== "" ? (
                                    <button type="submit" id="botoCrear" className="btn btn-primary">Crear certificat</button>
                                ) : (<></>)}
                                <button type="button" className="btn btn-danger ml-3" onClick={() => navigate(-1)}>Cancel·lar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
}