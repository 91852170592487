import React from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"
import { parseJwt } from "../Global"

export default function Header() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['user']);

    function handleLogOut(event) {
        event.preventDefault();
        setCookie('auth_token', '', { path: '/', maxAge: 0 });
        navigate("/login")
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-dark navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a id="collapse" className="nav-link" data-widget="pushmenu" href="/" role="button">
                        <i className="fas fa-bars" />
                    </a>
                </li>
                <li className="nav-item d-none d-md-inline-block">
                    <a href="/" className="nav-link">
                        <img
                            src="dist/img/logo-comunitat-regants-panta-blau-30x30.png"
                            alt="Pantà de Riudecanyes"
                            className="brand-image img-circle"
                            style={{ backgroundColor: "white" }}
                        />
                        <span className="ml-2">Inici</span>
                    </a>
                </li>
                <li className="nav-item d-none d-md-inline-block">
                    <a href="/comandes" className="nav-link">
                        Comandes
                    </a>
                </li>
                <li className="nav-item d-none d-md-inline-block">
                    <a href="/certificats-drets" className="nav-link">
                        Certificats de drets
                    </a>
                </li>
                <li className="nav-item d-none d-md-inline-block">
                    <a href="/contact" className="nav-link">
                        Contacte
                    </a>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown user-menu">
                    <a href="/" className="nav-link dropdown-toggle" data-toggle="dropdown" style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-user-circle fa-2x"></i>
                        <span className="d-md-inline ml-2">{parseJwt(cookies.auth_token).unique_name}</span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <li className="user-header bg-primary">
                            <p>
                                {parseJwt(cookies.auth_token).unique_name}
                                {parseJwt(cookies.auth_token).method === "emailPassword" ? (
                                    <small>{parseJwt(cookies.auth_token).email}</small>
                                ) : (
                                    <small>{parseJwt(cookies.auth_token).NIF}</small>
                                )}
                            </p>
                        </li>
                        <li className="user-body">
                            <div className="row">
                                <div className="col-8 text-center">
                                    <a href="/profile" className="btn btn-default btn-flat float-end">El meu compte</a>
                                </div>
                                <div className="col-4 text-center">
                                    <a href="/" onClick={handleLogOut} className="btn btn-default btn-flat float-end">Sortir</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}
