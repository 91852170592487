import React, { useState } from "react";
import { useLocation } from "react-router-dom"
import Comanda from "./Comanda";

export default function EditarComanda() {
    const comandaRealitzada = useLocation().state.comanda

    document.title = "Modificar Comanda - Pantà de Riudecanyes"

    var [comanda, setComanda] = useState(comandaRealitzada)

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Modificar comanda</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item"><a href="/comandes">Comandes</a></li>
                                <li className="breadcrumb-item active">Modificar comanda</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <Comanda accio='editar' comanda={comanda} setComanda={setComanda}/>
        </div >
    );
}